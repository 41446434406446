import React from 'react';
import Layout from '../components/Layout';
import { graphql, Link } from 'gatsby';

import InterestedBuyers from '../components/InterestedBuyers';
import {
  Row,
  Row10Col,
  BackpageBannerWrapper,
  BackpageBanner,
  PageTitle,
  MainPageContent,
} from '../components/CustomComponents';

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout
      location={location}
      pageMetaTitle={'404: Page Not Found'}
      pageMetaDescription={
        'Page Not Found. Please continue browsing our site to view at our available homes.'
      }
    >
      <BackpageBannerWrapper>
        <BackpageBanner
          fluid={data.file.childImageSharp.fluid}
        ></BackpageBanner>
      </BackpageBannerWrapper>
      <Row>
        <Row10Col>
          <MainPageContent>
            <PageTitle>Page not found</PageTitle>
            <div
              // you need this inner-content class for the margin on the last child to get taken off
              className="inner-content"
            >
              <p>
                Sorry... we could not find the page{' '}
                <strong>{location.pathname}</strong>.
              </p>
              <p>
                Please continue browsing <Link to="/">our site</Link> and make
                sure to view our{' '}
                <Link to="/available-homes">available homes</Link>.
              </p>
            </div>
          </MainPageContent>
        </Row10Col>
      </Row>
      <InterestedBuyers />
    </Layout>
  );
};

export default NotFoundPage;

export const NotFoundPageQuery = graphql`
  query NotFoundPageQuery {
    file(relativePath: { eq: "default-backpage-hero.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
